import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import CollectionsPage from './CollectionsPage';
import Contact from './contact.js';
import About from './about.js';

function App() {
  const [menuVisible, setMenuVisible] = useState(false);

  const handleMenuClick = (target) => {
    const element = document.querySelector(target);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setMenuVisible(false); // Close the menu after clicking a link
    }
  };

  const handleTouchStart = useCallback(() => {
    setMenuVisible((prevMenuVisible) => !prevMenuVisible);
  }, []);

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
    };
  }, [handleTouchStart]);

  return (
    <div className="App">
      {/* Menu */}
      <nav className={`menu ${menuVisible ? 'visible' : ''}`}>
        <ul>
          <li>
            <a href="#top" onClick={() => handleMenuClick('#top')}>
              <h2 className="menu-title">LOTTA ANDERSSON</h2>
            </a>
          </li>
          <li>
            <a href="#collections" onClick={() => handleMenuClick('#collections')}>
              SELECTION OF WORK
            </a>
          </li>
          <li>
            <a href="#about" onClick={() => handleMenuClick('#about')}>
              ABOUT
            </a>
          </li>
          <li>
            <a href="#contact" onClick={() => handleMenuClick('#contact')}>
              CONTACT
            </a>
          </li>
        </ul>
      </nav>

      {/* CollectionsPage */}
      <section id="collections">
        <CollectionsPage />
      </section>
      
      {/* About */}
      <section id="about">
        <About />
      </section>
     
      {/* Contact */}
      <section id="contact">
        <Contact />
      </section>
    </div>
  );
}

export default App;
