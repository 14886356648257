import React from 'react';
import './App.css'; // Import the CSS for the About component

function About() {
  return (
    <div className="about-container">
      <div className="about-text">
        <h2>About</h2>
        <p>
        My name is Charlotte. Throughout my entire life, I've been called Lotta. I am a creative soul with a deep understanding of the bigger picture and commercial interests. I am analytically attuned to the surrounding world, grounded in reality, and passionate about visual conceptualization. I enjoy leading my team in uncharted territories, and my leadership philosophy can be summarized as "freedom with responsibility".</p>
      </div>
      <div className="about-images">
        {/* Add your images here */}
      </div>
    </div>
  );
}

export default About;
