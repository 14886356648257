import React from 'react';
import './App.css';
import Arc1 from './Arc 1.jpg';
import Arc2 from './Arc 2.jpg';
import Arc3 from './Arc 3.jpg';
import Arc4 from './Arc 4.jpg';
import Arc5 from './Arc 5.jpg';
import Arc6 from './Arc 6.jpg';
import Kappahlkids1 from './Kappahl kids 1.jpg';
import Kappahlkids2 from './Kappahl kids 2.jpg';
import Kappahlkids3 from './Kappahl kids 3.jpg';
import Kappahlkids4 from './Kappahl kids 4.jpg';
import Kappahlkids5 from './Kappahl kids 5.jpg';
import Kappahlkids6 from './Kappahl kids 6.jpg';
import Kappahlnursing1 from './Kappahl nursing 1.jpg';
import Kappahlnursing2 from './Kappahl nursing 2.jpg';
import Kappahlnursing3 from './Kappahl nursing 3.jpg';
import Kappahlnursing4 from './Kappahl nursing 4.jpg';
import Kappahlnursing5 from './Kappahl nursing 5.jpg';
import Kappahlnursing6 from './Kappahl nursing 6.jpg';
import Spira1 from './spira 1.jpg';
import Spira2 from './Spira 2.jpg';
import Spira3 from './Spira 3.jpg';
import Spira4 from './Spira 4.jpg';
import Spira5 from './Spira 5.jpg';
import Spira6 from './Spira 6.jpg';
import Kappahlman1 from './Kappahl herr 1.jpg'; 
import Kappahlman2 from './Kappahl herr 2.jpg';
import Kappahlman3 from './Kappahl herr 3.jpg'; 
import Kappahlman4 from './Kappahl herr 4.jpg'; 
import Kappahlman5 from './Kappahl herr 5.jpg'; 
import Trend1 from './Trend 1.jpg';
import Trend2 from './Trend 2.jpg';

const collections = [
  {
    images: [Arc1, Arc2, Arc3, Arc4, Arc5, Arc6, Kappahlkids1, Kappahlkids2, Kappahlkids3, Kappahlkids4, Kappahlkids5, Kappahlkids6, Kappahlnursing1, Kappahlnursing2, Kappahlnursing3, Kappahlnursing4, Kappahlnursing5, Kappahlnursing6, Spira6, Spira1, Spira2, Spira4, Spira3, Spira5, Trend1, Trend2,Kappahlman1, Kappahlman2, Kappahlman3, Kappahlman5],
  }
];

function CollectionsPage() {
  return (
    <div className="collections-page">
      {collections.map((collection, index) => (
        <div key={index} className="collection">
          <h2>{collection.title}</h2>
          <div className="collection-images">
            {collection.images.map((image, imageIndex) => (
              <div key={imageIndex} className="collection-image-box">
                <img src={image} alt={`${imageIndex}`} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default CollectionsPage;
