import React from 'react';
import './App.css'; // Import the CSS for the About component

function Contact() {
  return (
    <div className="contact-container">
      <div className="contact-text">
        <h2>Contact</h2>
        <p>
          phone: <a href="tel:+46703179500">0703179500</a>
          <br />
          email: <a href="mailto:lotta@lottandersson.se">lotta@lottandersson.se</a>
        </p>
      </div>
      
      <div className="about-images">
        {/* Add your images here */}
      </div>
    </div>
  );
}

export default Contact;
